<template>
  <div class="py-6">
    <div class="container">
      <div class="row mt-3">
        <div class="col-md-12">
          <div class="card pb-4">
            <div class="card-header border-bottom-0 p-0 bg-white">
              <div>
                <!-- Nav -->
                <ul class="nav nav-lb-tab" id="tab" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" id="all-school-tab" data-bs-toggle="pill" href="#all-schools" role="tab"
                       aria-controls="courses" aria-selected="true">Tất cả ({{ schools.paginatorInfo.total }})</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="overdue-schools-tab" data-bs-toggle="pill" href="#overdue-schools"
                       role="tab"
                       aria-controls="approved" aria-selected="false">Hết dùng thử
                      ({{ overdueSchools.paginatorInfo.total }})</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="charging-schools-tab" data-bs-toggle="pill" href="#charging-schools"
                       role="tab"
                       aria-controls="pending" aria-selected="false">Đang thu phí
                      ({{ chargingSchools.paginatorInfo.total }})
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="suspend-schools-tab" data-bs-toggle="pill" href="#suspend-schools"
                       role="tab"
                       aria-controls="pending" aria-selected="false">Đang tạm dừng
                      ({{ suspendSchools.paginatorInfo.total }})
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div class="p-4 row">
              <form class="d-flex align-items-center">
                <input type="search" v-model="filter.name" class="form-control me-4" placeholder="Tên trường">
                <input type="search" v-model="filter.subdomain" class="form-control me-4" placeholder="Subdomain">
                <select class="form-control me-4" v-model="filter.status">
                  <option value="">-- Trạng thái --</option>
                  <option value=1>Đang hoạt động</option>
                  <option value=0>Tạm khóa</option>
                </select>
                <select class="form-control me-4" v-model="action">
                  <option value="">-- Thao tác --</option>
                  <option value="startCharge">Thu phí</option>
                  <option value="stopCharge">Dừng thu phí</option>
                  <option value="suspend">Tạm dừng</option>
                  <option value="unsuspend">Mở lại</option>
                </select>
                <button type="button" v-on:click="applyBatchSchools" class="btn btn-primary text-nowrap"
                        :disabled="action === '' || selectedSchoolIds.length === 0">Áp dụng
                </button>
              </form>
            </div>

            <div class="tab-content" id="tabContent">
              <div class="tab-pane fade show active" id="all-schools" role="tabpanel" aria-labelledby="all--tab">
                <school-table :schools="schools.data" unique="schools" v-on:updateSchools="updateSchools"
                              v-on:setCurrentSchool="setCurrentSchool"/>
                <v-pagination
                    v-if="schools.paginatorInfo.lastPage > 1"
                    v-model="schools.paginatorInfo.currentPage"
                    :pages="schools.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadSchools"
                />
              </div>
              <div class="tab-pane fade show" id="overdue-schools" role="tabpanel" aria-labelledby="overdue-tab">
                <school-table :schools="overdueSchools.data" unique="overdueSchools" v-on:updateSchools="updateSchools"
                              v-on:setCurrentSchool="setCurrentSchool"/>
                <v-pagination
                    v-if="overdueSchools.paginatorInfo.lastPage > 1"
                    v-model="overdueSchools.paginatorInfo.currentPage"
                    :pages="overdueSchools.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadOverdueSchools"
                />
              </div>
              <div class="tab-pane fade show" id="charging-schools" role="tabpanel" aria-labelledby="charging-tab">
                <school-table :schools="chargingSchools.data" unique="chargingSchools"
                              v-on:updateSchools="updateSchools" v-on:setCurrentSchool="setCurrentSchool"/>
                <v-pagination
                    v-if="chargingSchools.paginatorInfo.lastPage > 1"
                    v-model="chargingSchools.paginatorInfo.currentPage"
                    :pages="chargingSchools.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadChargingSchools"
                />
              </div>
              <div class="tab-pane fade show" id="suspend-schools" role="tabpanel" aria-labelledby="suspend-tab">
                <school-table :schools="suspendSchools.data" unique="suspendSchools" v-on:updateSchools="updateSchools"
                              v-on:setCurrentSchool="setCurrentSchool"/>
                <v-pagination
                    v-if="suspendSchools.paginatorInfo.lastPage > 1"
                    v-model="suspendSchools.paginatorInfo.currentPage"
                    :pages="suspendSchools.paginatorInfo.lastPage"
                    :range-size="1"
                    active-color="#DCEDFF"
                    @update:modelValue="loadSuspendSchools"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="removeSchoolModal" tabindex="-1" role="dialog" aria-labelledby="removeSchoolModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="removeSchoolModalLabel">
            {{ currentSchool.name }}
          </h4>
          <button id="removeSchoolModalClose" type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn xóa trường học này?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" data-bs-dismiss="modal" type="button" v-on:click="deleteSchool">
            Xóa trường học
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="startChargeSchoolModal" tabindex="-1" role="dialog"
       aria-labelledby="startChargeSchoolModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="startChargeSchoolModalLabel">
            {{ currentSchool.name }}
          </h4>
          <button id="startChargeSchoolModalClose" type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn thu phí trường học này?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-success" data-bs-dismiss="modal" type="button" v-on:click="startChargeSchool">
            Thu phí
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal fade" id="stopChargeSchoolModal" tabindex="-1" role="dialog"
       aria-labelledby="stopChargeSchoolModalLabel"
       aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="stopChargeSchoolModalLabel">
            {{ currentSchool.name }}
          </h4>
          <button id="stopChargeSchoolModalClose" type="button" class="btn-close" data-bs-dismiss="modal"
                  aria-label="Close">
            <span aria-hidden="true"><i class="fe fe-x-circle"></i></span>
          </button>
        </div>
        <div class="modal-body">
          <p>Bạn có chắc chắn muốn dừng thu phí trường học này?</p>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button class="btn btn-danger" data-bs-dismiss="modal" type="button" v-on:click="stopChargeSchool">
            Dừng thu phí
          </button>
        </div>
      </div>
    </div>
  </div>

  <Modal id="messageModal" :type="messageModalType" :message="messageModalMessage" :title="messageModalTitle"
         :message-detail="messageModalMessageDetail"/>
</template>

<script>
import ApiService from "@/core/services/api.service";
import VPagination from "@hennge/vue3-pagination";
import "@hennge/vue3-pagination/dist/vue3-pagination.css";
import {closeModal, getWhereCondition, openModal} from "@/core/services/utils.service";
import Modal from "@/components/Modal";
import SchoolTable from "@/components/admin/school/SchoolTable";

export default {
  name: "SchoolIndex",
  components: {
    VPagination,
    SchoolTable,
    Modal
  },
  data() {
    return {
      schools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      overdueSchools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      chargingSchools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      suspendSchools: {
        data: [],
        paginatorInfo: {
          currentPage: 1,
          lastPage: 0,
          total: 0
        }
      },
      currentSchool: {name: ""},
      action: "",
      filter: {
        name: "",
        subdomain: "",
        status: "",
      },
      selectedSchoolIds: [],
      messageModalType: "",
      messageModalMessage: "",
      messageModalTitle: "",
      messageModalMessageDetail: ""
    }
  },
  methods: {
    loadSchools() {
      let filters = {};

      for (let key in this.filter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.filter.hasOwnProperty(key)) {
          if (key === "name" || key === "subdomain") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "LIKE",
                value: "%" + this.filter[key] + "%"
              }
            }
          } else if (key === "status") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "EQ",
                value: this.filter[key]
              }
            }
          }
        }
      }

      let where = getWhereCondition(filters);

      let query = `query {
        schools(first: 5, page: ${this.schools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + where + `) {
          data {
            id
            code
            name
            subdomain
            status
            size
            created_at
            is_charging
            is_suspend
            days_charged
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.schools = data.data.schools;
              this.schools.data.map((school) => {
                school.is_checked = false;
              });
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadOverdueSchools() {
      let now = Date.now();
      let months = 3;
      let overdue_timestamp = now - months * 30 * 86400 * 1000;
      const dateObj = new Date(overdue_timestamp);
      const year = dateObj.getFullYear();
      const month = dateObj.getMonth() + 1;
      const date = dateObj.getDate();

      let filters = {
        created_at: {
          operator: "LT",
          value: `${year}-${month}-${date} 00:00:00`
        },
        is_charging: {
          operator: "EQ",
          value: 0
        },
        is_suspend: {
          operator: "EQ",
          value: 0
        }
      };

      for (let key in this.filter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.filter.hasOwnProperty(key)) {
          if (key === "name" || key === "subdomain") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "LIKE",
                value: "%" + this.filter[key] + "%"
              }
            }
          } else if (key === "status") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "EQ",
                value: this.filter[key]
              }
            }
          }
        }
      }

      let where = getWhereCondition(filters);

      let query = `query {
        schools(first: 5, page: ${this.overdueSchools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + where + `) {
          data {
            id
            code
            name
            subdomain
            status
            size
            created_at
            is_charging
            is_suspend
            days_charged
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.overdueSchools = data.data.schools;
              this.overdueSchools.data.map((school) => {
                school.is_checked = false;
              });
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadChargingSchools() {
      let filters = {
        is_charging: {
          operator: "EQ",
          value: 1
        },
        is_demo: {
          operator: "EQ",
          value: 0
        },
        is_warehouse: {
          operator: "EQ",
          value: 0
        }
      };
      for (let key in this.filter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.filter.hasOwnProperty(key)) {
          if (key === "name" || key === "subdomain") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "LIKE",
                value: "%" + this.filter[key] + "%"
              }
            }
          } else if (key === "status") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "EQ",
                value: this.filter[key]
              }
            }
          }
        }
      }

      let where = getWhereCondition(filters);

      let query = `query {
        schools(first: 5, page: ${this.chargingSchools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + where + `) {
          data {
            id
            code
            name
            subdomain
            status
            size
            created_at
            is_charging
            is_suspend
            days_charged
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.chargingSchools = data.data.schools;
              this.chargingSchools.data.map((school) => {
                school.is_checked = false;
              });
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    loadSuspendSchools() {
      let filters = {
        is_suspend: {
          operator: "EQ",
          value: 1
        },
        is_demo: {
          operator: "EQ",
          value: 0
        },
        is_warehouse: {
          operator: "EQ",
          value: 0
        }
      };
      for (let key in this.filter) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.filter.hasOwnProperty(key)) {
          if (key === "name" || key === "subdomain") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "LIKE",
                value: "%" + this.filter[key] + "%"
              }
            }
          } else if (key === "status") {
            if (this.filter[key] !== "") {
              filters[key] = {
                operator: "EQ",
                value: this.filter[key]
              }
            }
          }
        }
      }

      let where = getWhereCondition(filters);

      let query = `query {
        schools(first: 5, page: ${this.suspendSchools.paginatorInfo.currentPage}, orderBy: [{column: "created_at", order: DESC}]` + where + `) {
          data {
            id
            code
            name
            subdomain
            status
            size
            created_at
            is_charging
            is_suspend
            days_charged
            thumbnail {
              id
              link
              media_type
            }
          },
          paginatorInfo {
            total
            lastPage
            currentPage
          }
        }
      }`;

      ApiService.graphql(query)
          .then(({data}) => {
            if (data.data && data.data.schools) {
              this.suspendSchools = data.data.schools;
              this.suspendSchools.data.map((school) => {
                school.is_checked = false;
              });
            }
          })
          .catch(({response}) => {
            console.log(response);
          });
    },
    setCurrentSchool(school) {
      this.currentSchool = school;
    },
    updateSchools(unique, schools) {
      let thisSchools = [];
      this.selectedSchoolIds = [];

      switch (unique) {
        case "schools":
          thisSchools = this.schools.data;
          break;
        case "overdueSchools":
          thisSchools = this.overdueSchools.data;
          break;
        case "chargingSchools":
          thisSchools = this.chargingSchools.data;
          break;
        case "suspendSchools":
          thisSchools = this.suspendSchools.data;
          break;
      }

      for (let i = 0; i < thisSchools.length; i++) {
        schools.map((school) => {
          if (thisSchools[i].id === school.id) {
            thisSchools[i] = school;
            if (school.is_checked) {
              this.selectedSchoolIds.push(school.id);
            }
          }
        });
      }
    },
    deleteSchool() {
      this.messageModalTitle = "Xóa trường học";

      let query = `mutation($id: ID!) {
        deleteSchool(id: $id) {
          id
        }
      }`;

      ApiService.graphql(query, {id: this.currentSchool.id})
          .then(({data}) => {
            if (data.data && data.data.deleteSchool) {
              this.messageModalMessage = "Xóa trường học thành công";
              this.messageModalType = "success";
              closeModal("removeSchoolModal");
              openModal("messageModal");
              this.loadSchools();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("removeSchoolModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("removeSchoolModal");
            openModal("messageModal");
          });
    },
    applyBatchSchools() {
      if (confirm("Bạn có chắc chắn muốn thực hiện hành động này?") === true) {
        switch (this.action) {
          case "startCharge":
            this.startChargeBatchSchool();
            break;
          case "stopCharge":
            this.stopChargeBatchSchool();
            break;
          case "suspend":
            this.suspendBatchSchool();
            break;
          case "unsuspend":
            this.unsuspendBatchSchool();
            break;
        }
      }
    },
    startChargeSchool() {
      this.messageModalTitle = "Thu phí trường học";

      let query = `mutation($school_id: ID!) {
        startChargeSchool(school_id: $school_id)
      }`;

      ApiService.graphql(query, {school_id: this.currentSchool.id})
          .then(({data}) => {
            if (data.data && data.data.startChargeSchool) {
              this.messageModalMessage = "Thu phí trường học thành công";
              this.messageModalType = "success";
              closeModal("startChargeSchoolModal");
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("startChargeSchoolModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("startChargeSchoolModal");
            openModal("messageModal");
          });
    }
    ,
    stopChargeSchool() {
      this.messageModalTitle = "Dừng thu phí trường học";

      let query = `mutation($school_id: ID!) {
        stopChargeSchool(school_id: $school_id)
      }`;

      ApiService.graphql(query, {school_id: this.currentSchool.id})
          .then(({data}) => {
            if (data.data && data.data.stopChargeSchool) {
              this.messageModalMessage = "Dừng thu phí trường học thành công";
              this.messageModalType = "success";
              closeModal("stopChargeSchoolModal");
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              closeModal("stopChargeSchoolModal");
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            closeModal("stopChargeSchoolModal");
            openModal("messageModal");
          });
    },
    startChargeBatchSchool() {
      this.messageModalTitle = "Thu phí trường học hàng loạt";

      let query = `mutation($school_ids: [ID]!) {
        startChargeBatchSchool(school_ids: $school_ids)
      }`;

      ApiService.graphql(query, {school_ids: this.selectedSchoolIds})
          .then(({data}) => {
            if (data.data && data.data.startChargeBatchSchool) {
              this.messageModalMessage = "Thu phí trường học hàng loạt thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    stopChargeBatchSchool() {
      this.messageModalTitle = "Dừng thu phí trường học hàng loạt";

      let query = `mutation($school_ids: [ID]!) {
        stopChargeBatchSchool(school_ids: $school_ids)
      }`;

      ApiService.graphql(query, {school_ids: this.selectedSchoolIds})
          .then(({data}) => {
            if (data.data && data.data.stopChargeBatchSchool) {
              this.messageModalMessage = "Dừng thu phí trường học hàng loạt thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    suspendBatchSchool() {
      this.messageModalTitle = "Tạm dừng trường học hàng loạt";

      let query = `mutation($school_ids: [ID]!) {
        suspendBatchSchool(school_ids: $school_ids)
      }`;

      ApiService.graphql(query, {school_ids: this.selectedSchoolIds})
          .then(({data}) => {
            if (data.data && data.data.suspendBatchSchool) {
              this.messageModalMessage = "Tạm dừng trường học hàng loạt thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    unsuspendBatchSchool() {
      this.messageModalTitle = "Mở lại trường học hàng loạt";

      let query = `mutation($school_ids: [ID]!) {
        unsuspendBatchSchool(school_ids: $school_ids)
      }`;

      ApiService.graphql(query, {school_ids: this.selectedSchoolIds})
          .then(({data}) => {
            if (data.data && data.data.unsuspendBatchSchool) {
              this.messageModalMessage = "Mở lại trường học hàng loạt thành công";
              this.messageModalType = "success";
              openModal("messageModal");
              this.reloadAll();
            } else {
              this.messageModalMessage = data.errors[0].message;
              this.messageModalType = "danger";
              openModal("messageModal");
            }
          })
          .catch((response) => {
            this.messageModalMessage = response.message;
            this.messageModalType = "danger";
            openModal("messageModal");
          });
    },
    reloadAll() {
      this.loadSchools();
      this.loadOverdueSchools();
      this.loadChargingSchools();
      this.loadSuspendSchools();
    }
  },
  mounted() {
    this.reloadAll();
  }
  ,
  watch: {
    filter: {
      handler() {
        this.schools.paginatorInfo.currentPage = 1;
        this.loadSchools();
      }
      ,
      deep: true
    }
  }
  ,
}
</script>
