<template>
  <div class="table-responsive border-0 overflow-y-hidden">
    <table class="table mb-4 list">
      <thead class="table-light">
      <tr>
        <th scope="col">
          <div class="checkbox">
            <input type="checkbox" :id="'checkbox' + unique" v-model="isCheckedAll"/>
            <label :for="'checkbox' + unique"><span class="checkbox-icon"></span></label>
          </div>
        </th>
        <th scope="col">Tên trường học</th>
        <th scope="col">Sub domain</th>
        <th scope="col">Trạng thái</th>
        <th scope="col">Thu phí</th>
        <th scope="col">Số ngày sử dụng</th>
        <th scope="col">Dung lượng</th>
        <th scope="col">Ngày tạo</th>
        <th scope="col"></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(school, index) in mySchools" :key="'school' + unique + index">
        <td class="flex align-items-center">
          <div class="checkbox">
            <input type="checkbox" :id="'checkbox' + unique + school.id" v-model="school.is_checked"/>
            <label :for="'checkbox' + unique+ school.id"><span class="checkbox-icon"></span></label>
          </div>
        </td>
        <td class="align-middle">
          <div class="d-lg-flex align-items-center">
            <div class="thumbnail">
              <a :href="getDomain(school.subdomain)">
                <img :src="school.thumbnail.link" alt="" class="square" v-if="school.thumbnail">
              </a>
            </div>
            <div class="ms-lg-3 mt-2 mt-lg-0">
              <h4 class="mb-1 text-primary-hover">
                <a :href="getDomain(school.subdomain)" class="text-inherit">
                  {{ school.name }}
                </a>
              </h4>
            </div>
          </div>
        </td>
        <td class="align-middle">
          <span class="badge bg-primary">{{ school.subdomain }}</span>
        </td>
        <td class="align-middle">
          <span class="badge rounded-pill bg-success" v-if="school.is_suspend === 0">Đang hoạt động</span>
          <span class="badge rounded-pill bg-danger" v-if="school.is_suspend === 1">Tạm dừng</span>
        </td>
        <td class="align-middle">
          <span class="badge rounded-pill bg-success" v-if="school.is_charging  === 1">Đang thu phí</span>
          <span class="badge rounded-pill bg-danger" v-if="school.is_charging === 0">Chưa thu phí</span>
        </td>
        <td class="align-middle">
          {{ school.days_charged }}
        </td>
        <td class="align-middle">
          <span>{{ school.size >= 1000000000 ? Math.round(school.size / 1000000000) : !school.size ? "0" : "&lt;1" }} GB</span>
        </td>
        <td class="align-middle">
          {{ timeago(school.created_at) }}
        </td>
        <td class="text-muted align-middle border-top-0">
                            <span class="dropdown dropstart">
                              <a class="text-muted text-decoration-none" href="#" role="button" id="userDropdown"
                                 data-bs-toggle="dropdown" data-bs-offset="-20,20" aria-expanded="false">
                                <i class="fe fe-more-vertical"></i>
                              </a>
                              <span class="dropdown-menu" aria-labelledby="userDropdown" style="">
                                <span class="dropdown-header">Thao tác</span>
                                <router-link class="dropdown-item"
                                             :to="{name: 'AdminSchoolDetail', params: {code: school.code}}">
                                  <i class="fe fe-settings dropdown-item-icon"></i> Quản trị
                                </router-link>
                                <a :href="getDomain(school.subdomain)" class="dropdown-item">
                                  <i class="fe fe-eye dropdown-item-icon"></i> Xem
                                </a>
                                <router-link class="dropdown-item"
                                             :to="{name: 'AdminSchoolEdit', params: {code: school.code}}">
                                  <i class="fe fe-edit-2 dropdown-item-icon"></i> Sửa
                                </router-link>
                                <a class="dropdown-item" v-on:click="setCurrentSchool(school)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#removeSchoolModal"><i
                                    class="fe fe-trash-2 dropdown-item-icon"></i>Xóa</a>
                                <a class="dropdown-item" v-on:click="setCurrentSchool(school)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#startChargeSchoolModal"><i
                                    class="fe fe-dollar-sign dropdown-item-icon"></i>Thu phí</a>
                                <a class="dropdown-item" v-on:click="setCurrentSchool(school)"
                                   href="#" data-bs-toggle="modal"
                                   data-bs-target="#stopChargeSchoolModal"><i
                                    class="fe fe-dollar-sign dropdown-item-icon"></i>Dừng thu phí</a>
                              </span>
                            </span>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import {timeago} from "@/core/services/utils.service";

export default {
  name: "SchoolTable",
  props: {
    schools: Array,
    unique: String
  },
  data() {
    return {
      isCheckedAll: false,
      isChecked: false,
      mySchools: []
    }
  },
  created() {
    this.mySchools = this.schools;
  },
  watch: {
    schools: {
      handler(newVal) {
        this.mySchools = newVal;
      }
    },
    mySchools: {
      handler(newVal) {
        this.$emit("updateSchools", this.unique, newVal);
      },
      deep: true
    },
    isCheckedAll: {
      handler(newVal) {
        this.mySchools.map((school) => {
          school.is_checked = newVal;
        });
      }
    }
  },
  methods: {
    getDomain(subdomain) {
      let home_url = process.env.VUE_APP_HOME_URL;
      return home_url.replace("*", subdomain);
    },
    timeago(x) {
      return timeago(x);
    },
    setCurrentSchool(school) {
      this.$emit("setCurrentSchool", school);
    }
  }
}
</script>
